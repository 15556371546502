export const contentBlockTypes = [
  { id: 10, title: "Текст: заголовки, параграфы" },
  { id: 20, title: "Легенда - отдельные объекты" },
  { id: 30, title: "Легенда горизонтальная" },
  {
    id: 40,
    title:
      "Изображение (в том числе и диаграммы, доступные по ссылке на изображение)",
  },
  { id: 80, title: "Непрерывная шкала" },
  { id: 81, title: "Шкала с разрывами" },
  { id: 100, title: "Столбчатая диаграмма" },
];

export const nestedContentBlockTypes = [{ id: 1, title: "Вложенные блоки" }];

export const messages = {
  empty: "Не должно быть пустым",
  codeFormat: "Неверный формат кода (12-34)",
  invalidJson: "Некорректный JSON",
};

export const barChartDefaultData = `{  
  "title": "Доля сельского населения",
  "is_horizontal": false,
  "legends": ["Оренбург", "Поволжье", "Россия"],
  "datasets": [
    {
      "data": [40.345, 27.3, 25.3],
      "color": ["#0526a1", "#3d57b2", "#85a4d0"],
      "tooltip": ["Население", "Население", "Население"]
    }
  ],
  "is_hover_active": true,
  "aspect": 1,
  "bar_percentage": 0.7,
  "is_bar_labels_disabled": false,
  "bar_labels_font_size": 18,
  "legend_font_size": 14,
  "data_labels_description": "%",
  "is_default_bar_labels_color": false,
  "is_tooltip_enabled": true
}`;
